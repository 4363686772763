const ALL_RESOURCES_PATIENT_REFERENCE = [
  'ReferralRequest:patient',
  'DocumentManifest:patient',
  'Goal:patient',
  'Consent:patient',
  'DocumentReference:patient',
  'ImagingManifest:patient',
  'RiskAssessment:patient',
  'CareTeam:patient',
  'ImagingStudy:patient',
  'FamilyMemberHistory:patient',
  'Encounter:patient',
  'DeviceRequest:patient',
  'AllergyIntolerance:patient',
  'CarePlan:patient',
  'EpisodeOfCare:patient',
  'Procedure:patient',
  'List:patient',
  'Immunization:patient',
  'VisionPrescription:patient',
  'ProcedureRequest:patient',
  'Flag:patient',
  'Observation:patient',
  'DiagnosticReport:patient',
  'NutritionOrder:patient',
  'Condition:patient',
  'Composition:patient',
  'DetectedIssue:patient',
  'SupplyDelivery:patient',
  'ClinicalImpression:patient',
  'DeviceUseStatement:patient',
  'Task:subject',
  'Task:requester',
  'Task:patient',
  'Task:owner',
  'Task:focus',
  'Task:based-on',
  'SupplyRequest:requester',
  'Specimen:subject',
  'Specimen:patient',
  'ServiceDefinition:successor',
  'ServiceDefinition:predecessor',
  'ServiceDefinition:depends-on',
  'ServiceDefinition:derived-from',
  'ServiceDefinition:composed-of',
  'Sequence:patient',
  'Schedule:actor',
  'RiskAssessment:subject',
  'ResearchSubject:patient',
  'ResearchSubject:individual',
  'RequestGroup:subject',
  'RequestGroup:participant',
  'RequestGroup:patient',
  'RequestGroup:definition',
  'RelatedPerson:patient',
  'ReferralRequest:subject',
  'ReferralRequest:requester',
  'QuestionnaireResponse:subject',
  'QuestionnaireResponse:source',
  'QuestionnaireResponse:patient',
  'QuestionnaireResponse:author',
  'Provenance:target',
  'Provenance:entity-ref',
  'Provenance:patient',
  'Provenance:agent',
  'ProcessResponse:request',
  'ProcedureRequest:subject',
  'ProcedureRequest:replaces',
  'ProcedureRequest:performer',
  'ProcedureRequest:based-on',
  'Procedure:subject',
  'Procedure:performer',
  'PlanDefinition:successor',
  'PlanDefinition:predecessor',
  'PlanDefinition:derived-from',
  'PlanDefinition:depends-on',
  'PlanDefinition:composed-of',
  'Person:patient',
  'Person:link',
  'PaymentNotice:response',
  'PaymentNotice:request',
  'Patient:link',
  'Observation:subject',
  'Observation:performer',
  'MessageHeader:focus',
  'MedicationStatement:source',
  'MedicationStatement:subject',
  'MedicationRequest:subject',
  'MedicationRequest:requester',
  'MedicationDispense:subject',
  'MedicationDispense:receiver',
  'MedicationDispense:performer',
  'MedicationAdministration:subject',
  'MedicationRequest:patient',
  'MedicationAdministration:patient',
  'MedicationStatement:patient',
  'MedicationDispense:patient',
  'MedicationAdministration:performer',
  'Media:subject',
  'Media:patient',
  'MeasureReport:patient',
  'Measure:successor',
  'Measure:predecessor',
  'Measure:derived-from',
  'Measure:depends-on',
  'Measure:composed-of',
  'List:subject',
  'List:item',
  'List:source',
  'Library:successor',
  'Library:predecessor',
  'Library:derived-from',
  'Library:composed-of',
  'Library:depends-on',
  'ImplementationGuide:resource',
  'ImmunizationRecommendation:patient',
  'ImagingManifest:author',
  'GuidanceResponse:subject',
  'GuidanceResponse:patient',
  'Group:member',
  'Goal:subject',
  'Flag:subject',
  'Flag:author',
  'ExplanationOfBenefit:patient',
  'ExplanationOfBenefit:payee',
  'EnrollmentRequest:subject',
  'EnrollmentRequest:patient',
  'Encounter:subject',
  'EligibilityRequest:patient',
  'DocumentReference:subject',
  'DocumentReference:related-ref',
  'DocumentReference:author',
  'DocumentManifest:subject',
  'DocumentManifest:related-ref',
  'DocumentManifest:content-ref',
  'DocumentManifest:recipient',
  'DiagnosticReport:subject',
  'DocumentManifest:author',
  'DeviceUseStatement:subject',
  'DeviceRequest:subject',
  'DeviceRequest:priorrequest',
  'DeviceRequest:performer',
  'DeviceRequest:based-on',
  'Device:patient',
  'DetectedIssue:implicated',
  'Coverage:subscriber',
  'Coverage:policy-holder',
  'Coverage:payor',
  'Contract:term-topic',
  'Coverage:beneficiary',
  'Contract:subject',
  'Contract:signer',
  'Contract:patient',
  'Contract:agent',
  'Consent:data',
  'Consent:consentor',
  'Consent:actor',
  'Condition:subject',
  'Condition:evidence-detail',
  'Condition:asserter',
  'Composition:subject',
  'Composition:entry',
  'Composition:author',
  'Composition:attester',
  'CommunicationRequest:subject',
  'CommunicationRequest:sender',
  'CommunicationRequest:requester',
  'CommunicationRequest:recipient',
  'CommunicationRequest:patient',
  'CommunicationRequest:based-on',
  'Communication:subject',
  'Communication:recipient',
  'Communication:sender',
  'Communication:patient',
  'Communication:part-of',
  'Communication:based-on',
  'ClinicalImpression:subject',
  'ClaimResponse:patient',
  'Claim:payee',
  'Claim:patient',
  'ChargeItem:subject',
  'ChargeItem:patient',
  'ChargeItem:participant-actor',
  'ChargeItem:enterer',
  'CareTeam:subject',
  'CareTeam:participant',
  'CarePlan:performer',
  'CarePlan:subject',
  'BodySite:patient',
  'Basic:subject',
  'Basic:patient',
  'Basic:author',
  'AuditEvent:patient',
  'AuditEvent:entity',
  'AuditEvent:agent',
  'AppointmentResponse:patient',
  'AppointmentResponse:actor',
  'Appointment:patient',
  'Appointment:actor',
  'AllergyIntolerance:recorder',
  'AllergyIntolerance:asserter',
  'AdverseEvent:subject',
  'AdverseEvent:recorder',
  'ActivityDefinition:successor',
  'ActivityDefinition:predecessor',
  'ActivityDefinition:depends-on',
  'ActivityDefinition:derived-from',
  'ActivityDefinition:composed-of',
  'Account:subject',
  'Account:patient'
];

export { ALL_RESOURCES_PATIENT_REFERENCE };
